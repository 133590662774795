/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IZynoPermissions } from '../models/IZynoPermissions';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CorePermissionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IZynoPermissions
     * @throws ApiError
     */
    public getCorePermissionsGet(): CancelablePromise<IZynoPermissions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/core/permissions',
        });
    }

}
