import Pusher, { Channel } from 'pusher-js';

import { getApiRequestHeaders } from '@/openapi-clients/helpers';

import type { PusherEvent, PusherEventPayload } from './pusher.types';

export class PusherService {
    private static pusher?: Pusher;
    static currentAccountId?: string;
    static currentTenantId?: string;
    static accountChannel?: Channel;
    static tenantChannel?: Channel;

    static connect(accountId: string, tenantId: string) {
        if (this.pusher) {
            if (this.currentAccountId === accountId && this.currentTenantId === tenantId) {
                return;
            }
            this.disconnect();
        }

        this.pusher = new Pusher(import.meta.env.VITE_APP_PUSHER_KEY, {
            cluster: import.meta.env.VITE_APP_PUSHER_CLUSTER,
            channelAuthorization: {
                endpoint: `${import.meta.env.VITE_APP_API_URL}/core/pusher/auth`,
                transport: 'ajax',
                headersProvider: getApiRequestHeaders
            }
        });

        this.accountChannel = this.pusher.subscribe(`private-a-${accountId}`);
        this.tenantChannel = this.pusher.subscribe(`private-t-${tenantId}`);
    }

    static disconnect() {
        this.pusher?.disconnect();
        this.pusher = undefined;
        this.accountChannel = undefined;
        this.tenantChannel = undefined;
    }

    static updateTenantId(tenantId: string) {
        this.tenantChannel?.unsubscribe();
        this.tenantChannel = this.pusher?.subscribe(`private-t-${tenantId}`);
    }

    static subscribe<T extends PusherEvent>(event: T, handler: (data: PusherEventPayload<T>) => void) {
        this.tenantChannel?.bind(event, handler);
        this.accountChannel?.bind(event, handler);
    }

    static unsubscribe<T extends PusherEvent>(event: T, handler: (data: PusherEventPayload<T>) => void) {
        this.tenantChannel?.unbind(event, handler);
        this.accountChannel?.unbind(event, handler);
    }

    static get socketId() {
        return this.pusher?.connection.socket_id;
    }
}
