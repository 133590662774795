import { formatError } from '@signal24/vue-foundation';
import { type Dictionary, keyBy } from 'lodash';
import { computed, type Ref, ref } from 'vue';

import { apiClient } from '@/openapi-clients/suite';

const CacheLoaders = {
    coreLocations: () => apiClient.coreLocations.getCoreLocationsIndex(),
    forms: () => apiClient.forms.getFormsListForms({}),
    inventoryCategories: () => apiClient.inventoryCategories.getInventoryCategoriesIndex(),
    inventoryVendors: () => apiClient.inventoryVendors.getInventoryVendorsIndex(),
    salesCategories: () => apiClient.salesConfigProductCategories.getSalesConfigProductCategoriesIndex(),
    salesTaxes: () => apiClient.salesConfigTaxes.getSalesConfigTaxesIndex(),
    salesFinancialAccounts: () => apiClient.salesFinancialAccounts.getSalesFinancialAccountsIndex()
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export type ICacheKey = keyof typeof CacheLoaders;

type ICacheLoaderReturnTypes = {
    [K in ICacheKey]: ReturnType<(typeof CacheLoaders)[K]>;
};
export type CacheDataType<T extends ICacheKey> = Awaited<ICacheLoaderReturnTypes[T]>;

type TypeWithId = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [K in ICacheKey]: CacheDataType<K> extends any[] ? (CacheDataType<K>[number] extends { id: any } ? K : never) : never;
}[ICacheKey];

export interface AsyncRef<T> extends Ref<T> {
    loadError?: string;
    loadPromise?: Promise<void>;
}
export function asyncRef<T>(value: T): AsyncRef<T>;
export function asyncRef<T>(): AsyncRef<T | undefined>;
export function asyncRef<T>(value?: T): AsyncRef<T | undefined> {
    return ref(value) as AsyncRef<T>;
}

export class DataCache {
    static refCache: { [K in ICacheKey]?: AsyncRef<CacheDataType<K> | undefined> } = {};

    static getRef<T extends ICacheKey>(key: T): AsyncRef<CacheDataType<T> | undefined> {
        if (!this.refCache[key]) {
            const newRef = ref<CacheDataType<T> | undefined>() as AsyncRef<CacheDataType<T> | undefined>;
            newRef.loadPromise = CacheLoaders[key]().then(
                data => {
                    newRef.value = data as CacheDataType<T>;
                },
                err => {
                    newRef.loadError = formatError(err);
                }
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.refCache[key] = newRef as any; // ts issue
        }
        return this.refCache[key]!;
    }

    static getMapById<T extends TypeWithId>(key: T) {
        const ref = this.getRef(key);
        return computed(() => keyBy(ref.value, 'id') as Dictionary<CacheDataType<T>[number]>);
    }

    static reloadCaches() {
        for (const [key, ref] of Object.entries(this.refCache)) {
            ref.value = undefined;
            ref.loadError = undefined;
            ref.loadPromise = CacheLoaders[key as ICacheKey]().then(
                data => {
                    ref.value = data as CacheDataType<ICacheKey>;
                },
                err => {
                    ref.loadError = formatError(err);
                }
            );
        }
    }
}

export class TransientPreferenceCache {
    static get<T>(key: string) {
        const value = sessionStorage.getItem(`zs:tpref:${key}`);
        return value ? (JSON.parse(value) as T) : undefined;
    }

    static set<T>(key: string, value: T) {
        sessionStorage.setItem(`zs:tpref:${key}`, JSON.stringify(value));
    }
}
