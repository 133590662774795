import * as Sentry from '@sentry/browser';
import { ref, watch } from 'vue';
import type { Router } from 'vue-router';

import { PusherService } from './modules/core/exports/pusher';
import type { IIdentityResponse, IInstall } from './openapi-clients-generated/auth';
import type { IZynoPermissions } from './openapi-clients-generated/suite';
import { OtelState } from './otel';
import { DataCache } from './shared/data/cache';
import { Freshdesk } from './shared/services/freshdesk';
import { OpenReplay } from './shared/services/openreplay';

export type ITenant = IIdentityResponse['tenants'][number];

interface IInstallDetails {
    fingerprintReader: boolean;
}

class State {
    router!: Router;
    install = ref<Pick<IInstall, 'id'> | null>(import.meta.env.VITE_APP_AUTH_IID ? { id: import.meta.env.VITE_APP_AUTH_IID } : null);
    installCapabilities = ref<IInstallDetails | null>(null);
    user = ref<IIdentityResponse | null>(null);
    tenant = ref<Pick<ITenant, 'id' | 'name' | 'isAdmin'> | null>(null);
    permissions = ref<IZynoPermissions | null>(null);
    syncState: { installId?: string; userId?: string; tenantId?: string } = {};

    constructor() {
        watch([this.install, this.user, this.tenant], () => this.syncStates());
    }

    get isInstall() {
        return this.install.value !== null;
    }

    syncStates() {
        // Sentry & Otel have downstream dependencies on the tracker state
        this.syncTrackerState();

        this.syncCacheState();
        this.syncPusherState();
        this.syncFreshdeskState();
        this.syncSentryState();
        this.syncOtelState();

        this.syncState = {
            installId: this.install.value?.id,
            userId: this.user.value?.id,
            tenantId: this.tenant.value?.id
        };
    }

    syncCacheState() {
        DataCache.reloadCaches();
    }

    syncPusherState() {
        if (!this.user.value) {
            PusherService.disconnect();
        } else if (this.syncState.userId !== this.user.value?.id) {
            PusherService.connect(this.user.value.id, this.tenant.value!.id);
        } else if (this.syncState.tenantId !== this.tenant.value?.id) {
            PusherService.updateTenantId(this.tenant.value!.id);
        }
    }

    syncTrackerState() {
        if (this.user.value) {
            OpenReplay.start(this.user.value);
        } else {
            OpenReplay.stop();
        }
    }

    syncSentryState() {
        Sentry.setUser(
            this.user.value
                ? {
                      id: this.user.value.id,
                      email: this.user.value.email
                  }
                : null
        );
        Sentry.setTag('openReplaySessionID', OpenReplay.sessionId);
        Sentry.setTag('openReplaySessionUrl', OpenReplay.sessionUrl);
    }

    syncFreshdeskState() {
        if (this.user.value) {
            Freshdesk.configure(this.user.value, OpenReplay.sessionUrl);
        }
    }

    syncOtelState() {
        OtelState.installId = this.install.value?.id;
        OtelState.userId = this.user.value?.id;
        OtelState.tenantId = this.tenant.value?.id;
        OtelState.openReplaySessionId = OpenReplay.sessionId;
    }
}

export const GlobalState = new State();
