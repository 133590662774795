import type { Router } from 'vue-router';

import { GlobalState, type ITenant } from '@/global';
import { apiAuthClient } from '@/openapi-clients/auth';
import { apiClient } from '@/openapi-clients/suite';
import type { IIdentityResponse, IInstall, ILoginResponse } from '@/openapi-clients-generated/auth';
import { useStore } from '@/store';

class AuthService {
    authStorage: Storage = localStorage;
    activeJwt: string | null = null;
    activeJwtExp: number | null = null;

    constructor() {
        this.loadStoredJwt();
    }

    // INIT

    private loadStoredJwt() {
        const ssJwt = this.authStorage.getItem('zs:auth:sessionJwt');
        if (!ssJwt) return;

        const ssJwtExp = parseInt(this.authStorage.getItem('zs:auth:sessionJwtExp')!);
        if (ssJwtExp <= Date.now()) {
            return this.clearJwt();
        }

        this.activeJwt = ssJwt;
        this.activeJwtExp = ssJwtExp;
    }

    clearJwt() {
        this.activeJwt = null;
        this.activeJwtExp = null;
        this.authStorage.removeItem('zs:auth:sessionJwt');
        this.authStorage.removeItem('zs:auth:sessionJwtExp');
    }

    // STATE SETUP

    setInstall(install: Pick<IInstall, 'id'>) {
        this.authStorage = sessionStorage;
        GlobalState.install.value = install;
    }

    setUser(user: IIdentityResponse) {
        GlobalState.user.value = user;

        const tenant = user.tenants.find(t => t.id === user.tenantId)!;
        this.setTenant(tenant);
    }

    setTenant(tenant: Pick<ITenant, 'id' | 'name' | 'isAdmin'>) {
        GlobalState.tenant.value = tenant;
        this.authStorage.setItem('zs:auth:tenantId', tenant.id);
    }

    // SESSION MANAGEMENT

    async processLoginResponse(response: ILoginResponse) {
        this.setUser(response.identity);
        this.processRenewalResponse(response);
        await this.reloadPermissions();
    }

    async processRenewalResponse(response: Pick<ILoginResponse, 'jwt' | 'expiresAt'>) {
        this.activeJwt = response.jwt;
        this.activeJwtExp = response.expiresAt;

        this.authStorage.setItem('zs:auth:sessionJwt', response.jwt);
        this.authStorage.setItem('zs:auth:sessionJwtExp', String(response.expiresAt));
    }

    clearUserSession(cause?: string) {
        GlobalState.user.value = null;

        if (!GlobalState.install.value) {
            GlobalState.tenant.value = null;
        }

        this.authStorage.removeItem('zs:auth:sessionJwt');
        this.authStorage.removeItem('zs:auth:sessionJwtExp');

        if (cause) this.authStorage.setItem('zs:auth:sessionLogoutCause', cause);
    }

    clearUserSessionAndLogin({ router, cause, intendedUrl }: { router: Router; cause?: string; intendedUrl?: string }) {
        this.clearUserSession(cause);
        router.replace({ name: 'login', query: { intendedUrl: intendedUrl ?? router.currentRoute.value.path } });
    }

    // GETTERS

    get lastTenantId() {
        return this.authStorage.getItem('zs:auth:tenantId');
    }

    get logoutCause() {
        return this.authStorage.getItem('zs:auth:sessionLogoutCause');
    }

    get logoutCauseOnce() {
        const cause = this.logoutCause;
        this.authStorage.removeItem('zs:auth:sessionLogoutCause');
        return cause;
    }

    // LOADERS

    async loadExistingSession() {
        if (!this.activeJwt) return;
        if (this.activeJwtExp! <= Date.now()) return this.clearJwt();

        try {
            const result = await apiAuthClient.identity.getIdentityGetIdentity();
            this.setUser(result);
            await this.reloadPermissions();
        } catch (_) {
            // the 401 from the API middlware will have already cleared the user session before we get here
        }
    }

    async reloadPermissions() {
        try {
            GlobalState.permissions.value = await apiClient.corePermissions.getCorePermissionsGet();
        } catch (err) {
            useStore().globalError = 'There was an issue loading user permissions. Please check your Internet connection and refresh the page.';
        }
    }
}

export const Auth = new AuthService();
